import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { getAllData } from "../../Utils/CallApiUtils";
import "./DashBoard.css";
import {
  convertDateFormatWithOutTime,
  getDayName,
} from "../../Utils/commonFunction";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function DashBoard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [policyExpireData, setpolicyExpireData] = useState(null);
  const [PolitypeCountData, setPolitypeCounteData] = useState([]);
  const [birthData, setBirthData] = useState({
    TodayBirthdaysCount: 0,
    TodayBirthdays: [],
    UpcomingBirthdaysCount: 0,
    UpcomingBirthdays: [],
  });
  const [companyAndAgentData, setCompanyAndAgentData] = useState({
    companies: [],
    agents: [],
  });
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    getDashboardData();
    getCompanyAndAgentData();
    getBirthData();
    getPolicyExpire();
    getPolicytypeWiseCount();
  }, []);

  const numberFormatter = new Intl.NumberFormat("en-IN");

  const getDashboardData = async () => {
    await getAllData(
      "Dashboard/DashboardPremiumAmountAndPolicyCount",
      setIsLoading,
      setDashboardData
    );
  };

  const getCompanyAndAgentData = async () => {
    await getAllData(
      "Dashboard/AllCompanyAndAgent",
      setIsLoading,
      setCompanyAndAgentData
    );
  };

  const getBirthData = async () => {
    await getAllData("Dashboard/AllBirthdata", setIsLoading, setBirthData);
  };

  const getPolicyExpire = async () => {
    await getAllData(
      "Dashboard/AllNext30DayExpirePolicy",
      setIsLoading,
      setpolicyExpireData
    );
  };

  const getPolicytypeWiseCount = async () => {
    await getAllData(
      "Dashboard/PolicyTypeWiseCount",
      setIsLoading,
      setPolitypeCounteData
    );
  };

  // Chart Data for Financial Year Comparison
  const financialYearChartData = {
    labels: ["Previous Financial Year", "Current Financial Year"],
    datasets: [
      {
        label: "Total Policy Count",
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.8)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [
          dashboardData?.previousFinancialYear?.totalPolicyCount || 0,
          dashboardData?.currentFinancialYear?.totalPolicyCount || 0,
        ],
      },
      {
        label: "Total Net Amount",
        backgroundColor: "rgba(153,102,255,0.6)",
        borderColor: "rgba(153,102,255,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(153,102,255,0.8)",
        hoverBorderColor: "rgba(153,102,255,1)",
        data: [
          dashboardData?.previousFinancialYear?.totalNetAmount || 0,
          dashboardData?.currentFinancialYear?.totalNetAmount || 0,
        ],
      },
    ],
  };

  // Chart Data for Month Comparison
  const monthComparisonChartData = {
    labels: ["Previous Year Same Month", "Current Month"],
    datasets: [
      {
        label: "Total Policy Count",
        backgroundColor: "rgba(255,159,64,0.6)",
        borderColor: "rgba(255,159,64,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,159,64,0.8)",
        hoverBorderColor: "rgba(255,159,64,1)",
        data: [
          dashboardData?.previousYearSameMonth?.totalPolicyCount || 0,
          dashboardData?.currentMonth?.totalPolicyCount || 0,
        ],
      },
      {
        label: "Total Net Amount",
        backgroundColor: "rgba(54,162,235,0.6)",
        borderColor: "rgba(54,162,235,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54,162,235,0.8)",
        hoverBorderColor: "rgba(54,162,235,1)",
        data: [
          dashboardData?.previousYearSameMonth?.totalNetAmount || 0,
          dashboardData?.currentMonth?.totalNetAmount || 0,
        ],
      },
    ],
  };

  const exportDashboardDataToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Dashboard Data");
    // Styling for headers
    const headerStyle = {
      font: { bold: true, size: 12, color: { argb: "FFFFFFFF" } }, // White bold text
      alignment: { vertical: "middle", horizontal: "center" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF4CAF50" }, // Green background
      },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Styling for regular cells
    const cellStyle = {
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: { vertical: "middle", horizontal: "center" },
    };

    // Alternating row style for readability
    const altRowStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF0F0F0" }, // Light gray
      },
    };

    // Styling for section titles
    const titleStyle = {
      font: { bold: true, size: 14, color: { argb: "FF000000" } }, // Black bold text
      alignment: { vertical: "middle", horizontal: "center" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFBDBDBD" }, // Light gray background
      },
    };

    // Utility function to add section title
    const addSectionTitle = (title) => {
      const row = sheet.addRow([title]);
      row.font = { bold: true, size: 14 };
      row.alignment = { vertical: "middle", horizontal: "center" };
      row.getCell(1).style = titleStyle;
      sheet.mergeCells(`A${row.number}:G${row.number}`); // Merge title cells
    };

    // Utility to add alternating row colors
    const addRowWithStyle = (rowData, rowIndex) => {
      const row = sheet.addRow(rowData);
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.style = cellStyle;
        if (rowIndex % 2 === 0) {
          cell.style = { ...cellStyle, ...altRowStyle }; // Apply alternating style
        }
      });
    };

    // Add Financial Year Comparison Section
    addSectionTitle("Financial Year Comparison");

    const financialYearHeaders = [
      "",
      "Previous Financial Year",
      "Current Financial Year",
    ];
    sheet
      .addRow(financialYearHeaders)
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = headerStyle;
      });

    addRowWithStyle(
      [
        "Total Policy Count",
        dashboardData?.previousFinancialYear?.totalPolicyCount || 0,
        dashboardData?.currentFinancialYear?.totalPolicyCount || 0,
      ],
      1
    );

    addRowWithStyle(
      [
        "Total Net Amount",
        dashboardData?.previousFinancialYear?.totalNetAmount || 0,
        dashboardData?.currentFinancialYear?.totalNetAmount || 0,
      ],
      2
    );

    // Add Month Comparison Section
    addSectionTitle("Month Comparison");

    const monthComparisonHeaders = [
      "",
      "Previous Year Same Month",
      "Last Month",
      "Current Month",
    ];
    sheet
      .addRow(monthComparisonHeaders)
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = headerStyle;
      });

    addRowWithStyle([
      "Total Policy Count",
      dashboardData?.previousYearSameMonth?.totalPolicyCount || 0,
      dashboardData?.lastMonth?.totalPolicyCount || 0,
      dashboardData?.currentMonth?.totalPolicyCount || 0,
    ]);

    addRowWithStyle(
      [
        "Total Net Amount",
        dashboardData?.previousYearSameMonth?.totalNetAmount || 0,
        dashboardData?.lastMonth?.totalNetAmount || 0,
        dashboardData?.currentMonth?.totalNetAmount || 0,
      ],
      4
    );

    // Upcoming 30 Days Policy Expire Section
    addSectionTitle("Upcoming 30 Days Policy Expire");
    addRowWithStyle(["NOP", policyExpireData?.policiesCountOf30Day || 0], 1);
    addRowWithStyle(
      [
        "PremiumAmt",
        numberFormatter.format(policyExpireData?.totalNetAmountOf30Day || 0),
      ],
      2
    );

    addSectionTitle("Active and Expire Policies");

    const policyHeaders = ["Policy Type", "NOP", "Premium Amount"];
    sheet.addRow(policyHeaders).eachCell({ includeEmpty: true }, (cell) => {
      cell.style = headerStyle;
    });

    addRowWithStyle(
      [
        "Active Policy",
        policyExpireData?.totalActivePolicyCount || 0,
        numberFormatter.format(
          policyExpireData?.totalActivePolicyNetAmount || 0
        ),
      ],
      1
    );

    addRowWithStyle(
      [
        "Expire Policy",
        policyExpireData?.totalExpiredPolicyCount || 0,
        numberFormatter.format(
          policyExpireData?.totalExpiredPolicyNetAmount || 0
        ),
      ],
      2
    );

    // Add Insurance Companies Overview
    addSectionTitle("Insurance Companies Overview");

    const companyHeaders = [
      "Insurance Company Name",
      "Total NOP",
      "Total PremiumAmt",
      "Current Year NOP",
      "Current Year PremiumAmt",
      "Previous Year NOP",
      "Previous Year PremiumAmt",
    ];
    sheet.addRow(companyHeaders).eachCell({ includeEmpty: true }, (cell) => {
      cell.style = headerStyle;
    });

    companyAndAgentData.companies.forEach((company, index) => {
      addRowWithStyle(
        [
          company.insuranceCompanyName,
          company.totalPolicies,
          numberFormatter.format(company.totalNetAmount),
          company.currentYearTotalPolicies,
          numberFormatter.format(company.currentYearTotalNetAmount),
          company.previousYearTotalPolicies,
          numberFormatter.format(company.previousYearTotalNetAmount),
        ],
        index
      );
    });

    // Add Agents Overview Section
    addSectionTitle("Agents Overview");

    const agentHeaders = [
      "Agent Master Name",
      "Total NOP",
      "Total PremiumAmt",
      "Current Year NOP",
      "Current Year PremiumAmt",
      "Previous Year NOP",
      "Previous Year PremiumAmt",
    ];
    sheet.addRow(agentHeaders).eachCell({ includeEmpty: true }, (cell) => {
      cell.style = headerStyle;
    });

    companyAndAgentData.agents.forEach((agent, index) => {
      addRowWithStyle(
        [
          agent.agentMasterName || "N/A",
          agent.totalPolicies,
          numberFormatter.format(agent.totalNetAmount),
          agent.currentYearTotalPolicies,
          numberFormatter.format(agent.currentYearTotalNetAmount),
          agent.previousYearTotalPolicies,
          numberFormatter.format(agent.previousYearTotalNetAmount),
        ],
        index
      );
    });

    // Add Today's Birthdays Section
    addSectionTitle("Today's Birthdays");

    const todayBirthdayHeaders = ["Policyholder Name", "Mobile No"];
    sheet
      .addRow(todayBirthdayHeaders)
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = headerStyle;
      });

    birthData.todayBirthdays.forEach((birthday, index) => {
      addRowWithStyle([birthday.policyHolderName, birthday.mobile], index);
    });

    // Add Upcoming Birthdays Section
    addSectionTitle("Upcoming Birthdays (Next 7 Days)");

    const upcomingBirthdayHeaders = [
      "Policyholder Name",
      "DOB",
      "Day",
      "Mobile No",
    ];
    sheet
      .addRow(upcomingBirthdayHeaders)
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = headerStyle;
      });

    birthData.upcomingBirthdays.forEach((birthday, index) => {
      addRowWithStyle(
        [
          birthday.policyHolderName,
          birthday.dateOfBirth &&
            convertDateFormatWithOutTime(birthday.dateOfBirth),
          birthday.dateOfBirth && getDayName(birthday.dateOfBirth),
          birthday.mobile,
        ],
        index
      );
    });

    // Add Policy Type Overview Section
    // Add section title
    addSectionTitle("Policy Type Overview");

    // Define headers for the data
    const policyTypeHeaders = [
      "Policy Type",
      "Total NOP",
      "Total Premium Amount",
      "Current Year NOP",
      "Current Year Premium Amount",
      "Previous Year NOP",
      "Previous Year Premium Amount",
    ];

    // Add headers to the worksheet
    const headerRow = sheet.addRow(policyTypeHeaders);
    headerRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.style = headerStyle;
    });

    // Check if PolitypeCountData has items before adding rows
    if (PolitypeCountData && PolitypeCountData.length > 0) {
      PolitypeCountData.forEach((policy, index) => {
        // Ensure that each policy object has the necessary fields
        addRowWithStyle(
          [
            policy.policyTypeName || "N/A", // Fallback if no name is provided
            policy.totalPolicies || 0,
            numberFormatter.format(policy.totalNetAmount || 0), // Format totalNetAmount
            policy.currentYearPolicies || 0,
            numberFormatter.format(policy.currentYearNetAmount || 0),
            policy.previousYearPolicies || 0,
            numberFormatter.format(policy.previousYearNetAmount || 0),
          ],
          index + 1 // Offset index by 1 to keep correct row numbers
        );
      });
    } else {
      // If no data is present, add a row to indicate that
      addRowWithStyle(["No data available", "", "", "", "", "", ""], 1);
    }
    // Auto-size columns based on content
    sheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2; // Add padding for better readability
    });
    // Generate and save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "DashboardData.xlsx");
  };

  return (
    <>
      <Card>
        <Card.Header className="Card-Header headerStyle">
          <span className="headerTextStyle">
            <i class="fas fa-tachometer-alt iconStyle" aria-hidden="true"></i>
            Maa Consultancy Dashboard
          </span>
          <Button
            type="button"
            variant="success"
            onClick={exportDashboardDataToExcel}
          >
            <i className="fa fa-file-excel mr-2"></i>
            <span>Download Excel</span>
          </Button>
        </Card.Header>

        <Card.Body className="Card-Body">
          <Row className="MainRow">
            {/* Previous Financial Year */}
            <Col md={6} lg={3} sm={12}>
              <div className="metric-card">
                <div className="icon">
                  <i className="fa fa-calendar-minus-o"></i>
                </div>
                <h5>Previous Financial Year</h5>
                <h2>
                  NOP:{" "}
                  {dashboardData?.previousFinancialYear?.totalPolicyCount || 0}
                </h2>
                <h2>
                  PremiumAmt:{" "}
                  {numberFormatter.format(
                    dashboardData?.previousFinancialYear?.totalNetAmount || 0
                  )}
                </h2>
              </div>
            </Col>

            {/* Current Financial Year */}
            <Col md={6} lg={3} sm={12}>
              <div className="metric-card">
                <div className="icon">
                  <i className="fa fa-calendar-plus-o"></i>
                </div>
                <h5>Current Financial Year</h5>
                <h2>
                  NOP:{" "}
                  {dashboardData?.currentFinancialYear?.totalPolicyCount || 0}
                </h2>
                <h2>
                  PremiumAmt:{" "}
                  {numberFormatter.format(
                    dashboardData?.currentFinancialYear?.totalNetAmount || 0
                  )}
                </h2>
              </div>
            </Col>

            {/* Previous Year Same Month */}
            <Col md={6} lg={3} sm={12}>
              <div className="metric-card">
                <div className="icon">
                  <i className="fa fa-calendar-minus-o"></i>
                </div>
                <h5>Previous Year Same Month</h5>
                <h2>
                  NOP:{" "}
                  {dashboardData?.previousYearSameMonth?.totalPolicyCount || 0}
                </h2>
                <h2>
                  PremiumAmt:{" "}
                  {numberFormatter.format(
                    dashboardData?.previousYearSameMonth?.totalNetAmount || 0
                  )}
                </h2>
              </div>
            </Col>
            <Col md={6} lg={3} sm={12}>
              <div className="metric-card">
                <div className="icon">
                  <i className="fa fa-calendar-plus-o"></i>
                </div>
                <h5>Current Month</h5>
                <h2>
                  NOP: {dashboardData?.currentMonth?.totalPolicyCount || 0}
                </h2>
                <h2>
                  PremiumAmt:{" "}
                  {numberFormatter.format(
                    dashboardData?.currentMonth?.totalNetAmount || 0
                  )}
                </h2>
              </div>
            </Col>
         
          </Row>
          <Row className="MainRow">
          <Col md={6} lg={3} sm={12}>
              <div className="metric-card">
                <div className="icon">
                  <i className="fa fa-calendar-minus-o"></i>
                </div>
                <h5>Last Month</h5>
                <h2>NOP: {dashboardData?.lastMonth?.totalPolicyCount || 0}</h2>
                <h2>
                  PremiumAmt:{" "}
                  {numberFormatter.format(
                    dashboardData?.lastMonth?.totalNetAmount || 0
                  )}
                </h2>
              </div>
            </Col>
            
            {/* Upcoming 30 Days Policy Expire */}
            <Col md={6} lg={3} sm={12}>
              <div className="metric-card">
                <div className="icon">
                  <i className="fa fa-window-close-o"></i>
                </div>
                <h5>Upcoming 30 Days Policy Expire</h5>
                <h2>NOP: {policyExpireData?.policiesCountOf30Day || 0}</h2>
                <h2>
                  PremiumAmt:{" "}
                  {numberFormatter.format(
                    policyExpireData?.totalNetAmountOf30Day || 0
                  )}
                </h2>
              </div>
            </Col>
            <Col md={6} lg={3} sm={12}>
              <div
                className="metric-card"
                style={{
                  background: "linear-gradient(247deg, rgb(6 74 30), #069306)",
                }}
              >
                <div className="icon">
                  <i className="fa fa-check-square-o"></i>
                </div>
                <h5>Active Policy</h5>
                <h2>NOP: {policyExpireData?.totalActivePolicyCount || 0}</h2>
                <h2>
                  PremiumAmt:{" "}
                  {numberFormatter.format(
                    policyExpireData?.totalActivePolicyNetAmount || 0
                  )}
                </h2>
              </div>
            </Col>
            <Col md={12} lg={3} sm={12}>
              <div
                className="metric-card"
                style={{
                  background: "linear-gradient(247deg, rgb(118 4 38), #c10808)",
                }}
              >
                <div className="icon" style={{ color: "white" }}>
                  <i
                    className="fa fa-window-close-o"
                    style={{ opacity: "1" }}
                  ></i>
                </div>
                <h5>Expire Policy</h5>
                <h2>NOP: {policyExpireData?.totalExpiredPolicyCount || 0}</h2>
                <h2>
                  PremiumAmt:{" "}
                  {numberFormatter.format(
                    policyExpireData?.totalExpiredPolicyNetAmount || 0
                  )}
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card className="tableCard">
                <Card.Header>Financial Year Comparison</Card.Header>
                <Card.Body>
                  <Bar
                    data={financialYearChartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        title: {
                          display: true,
                          text: "Previous vs Current Financial Year",
                        },
                      },
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>

            {/* Month Comparison Chart */}
            <Col md={6}>
              <Card className="tableCard">
                <Card.Header>Month Comparison</Card.Header>
                <Card.Body>
                  <Bar
                    data={monthComparisonChartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        title: {
                          display: true,
                          text: "Previous Year Same Month vs Current Month",
                        },
                      },
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {/* Companies Table */}
              <Card className="tableCard">
                <Card.Header>Insurance Companies</Card.Header>
                <Card.Body>
                  <div className="table-scroll-wrapper">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Insurance Company Name</th>
                          <th>NOP</th>
                          <th>PremiumAmt.</th>
                          <th>Current Year NOP</th>
                          <th>Current Year PremiumAmt.</th>
                          <th>Previous Year NOP</th>
                          <th>Previous Year PremiumAmt.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyAndAgentData.companies.map((company) => (
                          <tr key={company.insuranceCompanyID}>
                            <td>{company.insuranceCompanyName}</td>
                            <td>
                              {numberFormatter.format(company.totalPolicies)}
                            </td>
                            <td>
                              {numberFormatter.format(company.totalNetAmount)}
                            </td>
                            <td>
                              {numberFormatter.format(
                                company.currentYearTotalPolicies
                              )}
                            </td>
                            <td>
                              {numberFormatter.format(
                                company.currentYearTotalNetAmount
                              )}
                            </td>
                            <td>
                              {numberFormatter.format(
                                company.previousYearTotalPolicies
                              )}
                            </td>
                            <td>
                              {numberFormatter.format(
                                company.previousYearTotalNetAmount
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Agents Table */}
            <Col md={6}>
              <Card className="tableCard">
                <Card.Header>Agents Overview</Card.Header>
                <Card.Body>
                  <div className="table-scroll-wrapper">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Agent Master Name</th>
                          <th>NOP</th>
                          <th>PremiumAmt.</th>
                          <th>Current Year NOP</th>
                          <th>Current Year PremiumAmt.</th>
                          <th>Previous Year NOP</th>
                          <th>Previous Year PremiumAmt.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyAndAgentData.agents.map((agent) => (
                          <tr key={agent.agentMasterID || "null"}>
                            <td>{agent.agentMasterName || "N/A"}</td>
                            <td>
                              {numberFormatter.format(agent.totalPolicies)}
                            </td>
                            <td>
                              {numberFormatter.format(agent.totalNetAmount)}
                            </td>
                            <td>
                              {numberFormatter.format(
                                agent.currentYearTotalPolicies
                              )}
                            </td>
                            <td>
                              {numberFormatter.format(
                                agent.currentYearTotalNetAmount
                              )}
                            </td>
                            <td>
                              {numberFormatter.format(
                                agent.previousYearTotalPolicies
                              )}
                            </td>
                            <td>
                              {numberFormatter.format(
                                agent.previousYearTotalNetAmount
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* Today's Birthdays Table */}
            <Col md={6}>
              <Card className="tableCard">
                <Card.Header>
                  Today's Birthdays (Count: {birthData.todayBirthdaysCount})
                </Card.Header>
                <Card.Body>
                  <div className="table-scroll-wrapper">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Policyholder Name</th>
                          <th>Mobile No</th>
                        </tr>
                      </thead>
                      <tbody>
                        {birthData.todayBirthdays &&
                        birthData.todayBirthdays.length > 0 ? (
                          birthData.todayBirthdays.map((birthday, index) => (
                            <tr key={index}>
                              <td>{birthday.policyHolderName}</td>
                              <td>{birthday.mobile}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No birthdays today</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Upcoming Birthdays Table */}
            <Col md={6}>
              <Card className="tableCard">
                <Card.Header>
                  Upcoming Birthdays Next 7 Days (Count:{" "}
                  {birthData.upcomingBirthdaysCount})
                </Card.Header>
                <Card.Body>
                  <div className="table-scroll-wrapper">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Policyholder Name</th>
                          <th>DOB</th>
                          <th>Day</th>
                          <th>Mobile No</th>
                        </tr>
                      </thead>
                      <tbody>
                        {birthData.upcomingBirthdays &&
                        birthData.upcomingBirthdays.length > 0 ? (
                          birthData.upcomingBirthdays.map((birthday, index) => (
                            <tr key={index}>
                              <td>{birthday.policyHolderName}</td>
                              <td>
                                {" "}
                                {birthday.dateOfBirth &&
                                  convertDateFormatWithOutTime(
                                    birthday.dateOfBirth
                                  )}
                              </td>
                              <td>
                                {" "}
                                {birthday.dateOfBirth &&
                                  getDayName(birthday.dateOfBirth)}
                              </td>
                              <td>{birthday.mobile}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4">No upcoming birthdays</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className="tableCard">
                <Card.Header>Policy Type Overview</Card.Header>
                <Card.Body>
                  <div className="table-scroll-wrapper">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Policy Type</th>
                          <th>Total NOP</th>
                          <th>Total Premium Amount</th>
                          <th>Current Year NOP</th>
                          <th>Current Year Premium Amount</th>
                          <th>Previous Year NOP</th>
                          <th>Previous Year Premium Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {PolitypeCountData.length > 0 ? (
                          PolitypeCountData.map((policy) => (
                            <tr key={policy.PolicyTypeID}>
                              <td>{policy.policyTypeName}</td>
                              <td>
                                {numberFormatter.format(policy.totalPolicies)}
                              </td>
                              <td>
                                {numberFormatter.format(policy.totalNetAmount)}
                              </td>
                              <td>
                                {numberFormatter.format(
                                  policy.currentYearPolicies
                                )}
                              </td>
                              <td>
                                {numberFormatter.format(
                                  policy.currentYearNetAmount
                                )}
                              </td>
                              <td>
                                {numberFormatter.format(
                                  policy.previousYearPolicies
                                )}
                              </td>
                              <td>
                                {numberFormatter.format(
                                  policy.previousYearNetAmount
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
