import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { getSingleData } from "../../Utils/CallApiUtils";
import { getSingleDataReturn } from "../../Utils/CallApiOnlyReturn";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";
import { Modal } from "react-bootstrap";
import EndorsementDetailModel from "../AllPolicyDetailModel/EndorsementDetailModel";
import CourierDetailModel from "../AllPolicyDetailModel/CourierDetailModel";
import { usePolicyDetailContext } from "./PolicyDetailContext";
import UserSetting from "../../UserSettings.json";
import SendSMSComponent from "../Policy/SendSMSComponent";
import GlobalModal from "../../Common/GlobalModal";

export default function PolicyDetailModel({ selectedPolicyID }) {
  const [policyData, setPolicyData] = useState([]);
  const { setIsLoading } = useLoadingContext();
  const { setActiveKey, setPolicyIDForDetails } = usePolicyDetailContext();
  const [showSendSMSModel, setShowSendSMSModel] = useState(false);
  const [PolicyIDForSendSMS, setPolicyIDForSendSMS] = useState(null);

  useEffect(() => {
    getPolicyDetail();
  }, [selectedPolicyID]);

  const getPolicyDetail = async () => {
    const Result = await getSingleData(
      `Policy/PolicyTreeData?PolicyID=${selectedPolicyID}`,
      setPolicyData,
      setIsLoading
    );

    if (Result) {
      console.log(Result);
    }
  };

  const fetchAndShowData = async (policyID, type) => {
    if (type === "Claim") {
      setActiveKey("claim");
      setPolicyIDForDetails(policyID);
    } else if (type === "Endorsement") {
      setActiveKey("endorsement");
      setPolicyIDForDetails(policyID);
    } else if (type === "Courier") {
      setActiveKey("courier");
      setPolicyIDForDetails(policyID);
    }
    return null;
  };

  const HandleSendSMSModel = () => {
    setShowSendSMSModel(false);
  };

  const renderPolicyRows = (policies) => {
    return policies.map((policy) => (
      <tr
        key={policy.policyID}
        className={`policy-${policy.policyTreeStatus.toLowerCase()}`}
      >
        <td
          className="text-center"
          style={{
            verticalAlign: "middle",
            letterSpacing: "10px",
            cursor: "pointer",
          }}
        >
          <div className="d-flex justify-content-around">
            {policy.imagePath  && (
              <a
                href={UserSetting.imgURL + policy.imagePath}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <i
                  style={{ fontSize: "1.6em", color: "black" }}
                  className="fas fa-file-pdf"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View PDF"
                  onMouseOver={(e) => {
                    e.target.style.color = "red";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "black";
                  }}
                ></i>
              </a>
            )}

            <i
              style={{ fontSize: "1.6em", color: "black" }}
              className="fas fa-receipt"
              onClick={() => fetchAndShowData(policy.policyID, "Claim")}
              data-toggle="tooltip"
              data-placement="top"
              title="Claim"
              onMouseOver={(e) => {
                e.target.style.color = "blue";
              }}
              onMouseOut={(e) => {
                e.target.style.color = "black";
              }}
            ></i>

            <i
              style={{ fontSize: "1.6em", color: "black" }}
              className="fas fa-users"
              onClick={() => fetchAndShowData(policy.policyID, "Endorsement")}
              data-toggle="tooltip"
              data-placement="top"
              title="Endorsement"
              onMouseOver={(e) => {
                e.target.style.color = "green";
              }}
              onMouseOut={(e) => {
                e.target.style.color = "black";
              }}
            ></i>

            <i
              style={{ fontSize: "1.6em", color: "black" }}
              className="fas fa-truck"
              onClick={() => fetchAndShowData(policy.policyID, "Courier")}
              data-toggle="tooltip"
              data-placement="top"
              title="Courier"
              onMouseOver={(e) => {
                e.target.style.color = "purple";
              }}
              onMouseOut={(e) => {
                e.target.style.color = "black";
              }}
            ></i>
            <i
              className="fas fa-paper-plane"
              style={{
                fontSize: "1.6em",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowSendSMSModel(true);
                setPolicyIDForSendSMS(policy.policyID);
              }}
              onMouseOver={(e) => {
                e.target.style.color = "blue";
              }}
              onMouseOut={(e) => {
                e.target.style.color = "black";
              }}
            ></i>
          </div>
        </td>

        <td>{policy.policyHolderName}</td>
        <td>{policy.policyTypeName}</td>
        <td>{policy.clientMasterName}</td>
        <td>{policy.insuranceCompanyName}</td>
        <td>{policy.tpaMasterName}</td>
        <td>{policy.policyNo}</td>
        <td>{policy.previousPolicyNo}</td>
        <td>{convertDateFormatWithOutTime(policy.policyStartDate)}</td>
        <td>{convertDateFormatWithOutTime(policy.policyEndDate)}</td>
        <td>{policy.premiumAmt ? policy.premiumAmt.toFixed(2) : ""}</td>
        <td>{policy.serviceTax ? policy.serviceTax.toFixed(2) : ""}</td>
        <td>{policy.serviceTaxAmount ? policy.serviceTaxAmount.toFixed(2) : ""}</td>
        <td>{policy.netAmount ? policy.netAmount.toFixed(2) : ""}</td>
        <td>{policy.renewalFlag ? "true" : "false"}</td>
        <td>{policy.mobile}</td>
        <td>{policy.emailIdTo}</td>
        <td>{policy.emailIdCC === "null" ? "" : policy.emailIdCC}</td>
        <td>{policy.internalRemarks}</td>
        <td>{policy.externalRemarks}</td>
      </tr>
    ));
  };

  return (
    <>
      <Card className="policy-card">
        <Card.Body>
          <div className="policy-section policy-scroll">
            {policyData.filter((policy) => policy.policyTreeStatus === "New")
              .length > 0 && (
              <div className="policy-table">
                <div className="policy-table-title">New Policies</div>
                <div className="policy-table-content">
                  <table className="table-centered">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Holder Name</th>
                        <th>Policy Type</th>
                        <th>Client Name</th>
                        <th>Insurance Company</th>
                        <th>TPA Name</th>
                        <th>Policy No</th>
                        <th>Previous Policy No</th>
                        <th>Policy Start Date</th>
                        <th>Policy End Date</th>
                        <th>Premium Amount</th>
                        <th>Service Tax</th>
                        <th>Service Tax Amt.</th> 
                        <th>Net Amout</th>
                        <th>Renewal Flag</th>
                        <th>Mobile</th>
                        <th>Email Id To</th>
                        <th>Email Id CC</th>
                        <th>Internal Remarks</th>
                        <th>External Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderPolicyRows(
                        policyData.filter(
                          (policy) => policy.policyTreeStatus === "New"
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {policyData.filter(
              (policy) => policy.policyTreeStatus === "Current"
            ).length > 0 && (
              <div className="policy-table">
                <div className="policy-table-title">Current Policies</div>
                <div className="policy-table-content">
                  <table className="table-centered">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Holder Name</th>
                        <th>Policy Type</th>
                        <th>Client Name</th>
                        <th>Insurance Company</th>
                        <th>TPA Name</th>
                        <th>Policy No</th>
                        <th>Previous Policy No</th>
                        <th>Policy Start Date</th>
                        <th>Policy End Date</th>
                        <th>Premium Amount</th>
                        <th>Service Tax</th>
                        <th>Service Tax Amt.</th>
                        <th>Net Amout</th>
                        <th>Renewal Flag</th>
                        <th>Mobile</th>
                        <th>Email Id To</th>
                        <th>Email Id CC</th>
                        <th>Internal Remarks</th>
                        <th>External Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderPolicyRows(
                        policyData.filter(
                          (policy) => policy.policyTreeStatus === "Current"
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {policyData.filter((policy) => policy.policyTreeStatus === "Old")
              .length > 0 && (
              <div className="policy-table">
                <div className="policy-table-title">Old Policies</div>
                <div className="policy-table-content">
                  <table className="table-centered">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Holder Name</th>
                        <th>Policy Type</th>
                        <th>Client Name</th>
                        <th>Insurance Company</th>
                        <th>TPA Name</th>
                        <th>Policy No</th>
                        <th>Previous Policy No</th>
                        <th>Policy Start Date</th>
                        <th>Policy End Date</th>
                        <th>Premium Amount</th>
                        <th>Service Tax</th>
                        <th>Service Tax Amt.</th>
                        <th>Net Amout</th>
                        <th>Renewal Flag</th>
                        <th>Mobile</th>
                        <th>Email Id To</th>
                        <th>Email Id CC</th>
                        <th>Internal Remarks</th>
                        <th>External Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderPolicyRows(
                        policyData.filter(
                          (policy) => policy.policyTreeStatus === "Old"
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
        <GlobalModal
          title="Send SMS"
          bodyComponent={
            <SendSMSComponent
              policyID={PolicyIDForSendSMS}
              onCloseSendSMS={() => {
                setShowSendSMSModel(false);
              }}
            />
          }
          isOpen={showSendSMSModel}
          onClose={HandleSendSMSModel}
        />
      </Card>
    </>
  );
}
