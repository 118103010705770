import React, { useEffect, useInsertionEffect, useState, useRef } from "react";
import { getSingleData, deleteData } from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  Card,
  Row,
  Tab,
  Tabs,
  Col,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import DocumentTypeComponent from "../DocumentTypes/DocumentTypesComponent";
import Loader from "../../Common/Loader ";
import ImageAddAndPreview from "../../Common/ImageAddAndPreview";
import api from "../../Utils/api";
import UserSetting from "../../UserSettings.json";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import TextArea from "../../Common/TextArea";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyKYCOfPolicy = {
  policyID: 0,
  DocumentTypeID: 0,
  Remarks: "",
};

export default function KYCComponent({ policyID }) {
  const [isLoading, setIsLoading] = useState(false);
  const [KYCDataList, setKYCDataList] = useState([]);
  const [formData, setFormData] = useState(emptyKYCOfPolicy);
  const [ddValidationError, setDDValidationError] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedImage, setSelectedImage] = useState([]);
  const KYCdocumentTypeFormRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const [PolicyNoForFindKYC, setPolicyNoForFindKYC] = useState("");
  const [OtherPolicyKYCDataList, setOtherPolicyKYCDataList] = useState([]);
  useEffect(() => {
    if (policyID) {
      getKYCDataOfPolicy();
    }
  }, [policyID]);

  const getKYCDataOfPolicy = async () => {
    await getSingleData(
      `KYC/PolicyAllKYCDocument?policyID=${policyID}`,
      setKYCDataList,
      setIsLoading
    );
  };

  const insertKYCDocument = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = KYCdocumentTypeFormRef.current;
    if (!form.checkValidity()) {
      setDDValidationError(formData.DocumentTypeID === 0);
      setIsLoading(false);
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("PolicyID", policyID);
      form_Data.append("DocumentTypeID", formData.DocumentTypeID);
      form_Data.append("Remarks", formData.Remarks);
      form_Data.append("image", selectedImage);
      // for (let i = 0; i < selectedImage.length; i++) {
      //   form_Data.append(`image[${i}]`, selectedImage[i]);
      // }

      api
        .post("KYC/InsertPolicyKYCDocument", form_Data)
        .then((result) => {
          showMessage(result.data.statusCode, result.data.message);
          if (result.status === 200) {
            setValidated(false);
            getKYCDataOfPolicy();
          }
        })
        .catch((error) => {
          console.log(error);
          showMessage(400, "An error occurred");
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyKYCOfPolicy);
    setRefreshKey(refreshKey + 1);
    setSelectedImage([]);
  };

  const deleteKYCDocument = async (id) => {
    await deleteData(
      `KYC/DeletePolicyKYCDocument?ImageForAllID=${id}`,
      getKYCDataOfPolicy,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("Document Type Name", "documentType", 150),
    createColumnDef("Remarks", "remarks", 300),
    {
      headerName: "View",
      width: 80,
      field: "imagePath",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <a href={UserSetting.imgURL + value} target="_blank">
          <ion-icon name="eye-outline" class="iconsFonts"></ion-icon>
        </a>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 80,
      field: "imageForAllID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteKYCDocument(value)}
        ></ion-icon>
      ),
      pinned: "right",
    },
  ];

  const handleImageUpload = (imageFile) => {
    setSelectedImage(imageFile);
  };

  const handlePreviousPolicyNoKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePolicyByPreviousPolicyNo(e.target.value);
    }
  };

  const handlePolicyByPreviousPolicyNo = (PolicyNo) => {
    setIsLoading(true);
    api
      .get(`Policy/GetAllPolicyKYCByPolicyNo?PolicyNo=${PolicyNo}`)
      .then((result) => {
        if (result.data.statusCode === 200) {
          const kycData = result.data.data;
          setOtherPolicyKYCDataList(kycData);
        } else {
          showMessage(400, result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        showMessage(400, "An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteKYCDocumentOfOtherPolicyFind = (id) =>{
    const updatedList = OtherPolicyKYCDataList.filter(item => item.imageForAllID !== id);
    setOtherPolicyKYCDataList(updatedList);
  }
             
  const SaveKYCofOtherPolicy = () => {
    if (!OtherPolicyKYCDataList || OtherPolicyKYCDataList.length === 0) {
        showMessage(400, "No KYC documents to save.");
        return;
    }

    // Validation: Check if all required fields in each detail object are filled
    for (const detail of OtherPolicyKYCDataList) {
        if (!detail.documentType || !detail.imagePath ) {
            showMessage(400, "All fields (Document Type, Image Path, and Remarks) are required for each KYC document.");
            return;
        }
    }
    const Data = OtherPolicyKYCDataList.map((detail) => ({
        PolicyID: policyID,
        DocumentType: detail.documentType,
        ImagePath: detail.imagePath,
        Remarks: detail.remarks,
    }));

    api.post("Policy/SetKYCAlsoOtherPolicy", Data)
        .then((result) => {
            showMessage(result.data.statusCode, result.data.message);
            getKYCDataOfPolicy(result.data.data);
        })
        .catch((error) => {
            console.error(error);
            showMessage(400, "An error occurred while saving KYC documents.");
        })
        .finally(() => {
            // Clear the list and reset inputs
            setOtherPolicyKYCDataList([]);
            setPolicyNoForFindKYC("");
        });
};


  const columnDefsForOtherPolicyKYCDataList = [
    createColumnDef("Document Type Name", "documentType", 150),
    createColumnDef("Remarks", "remarks", 300),
    {
      headerName: "View",
      width: 80,
      field: "imagePath",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <a href={UserSetting.imgURL + value} target="_blank">
          <ion-icon name="eye-outline" class="iconsFonts"></ion-icon>
        </a>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 80,
      field: "imageForAllID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteKYCDocumentOfOtherPolicyFind(value)}
        ></ion-icon>
      ),
      pinned: "right",
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <Tabs defaultActiveKey="addKYC" className="kycTabs  mb-3">
        <Tab eventKey="addKYC" title="Add KYC">
          <Card.Body>
            <GlobalFormTagWithSubmit
              validated={validated}
              refName={KYCdocumentTypeFormRef}
              formName="KYCdocumentType"
            >
              <Row>
                <GlobalDDComponent
                  ColSize={8}
                  formLabel="Document Type"
                  apiEndpoint="DocumentType/AllDocumentTypes"
                  valueOfDD="documentTypeID"
                  labelOfDD="documentTypeName"
                  onSelectOption={(DocumentTypeID) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      DocumentTypeID: DocumentTypeID,
                    }));
                    setDDValidationError(false);
                  }}
                  selectedOptionOfComponent={formData.DocumentTypeID}
                  ValidationError={ddValidationError}
                  offcanvasTitle="Add Document Type"
                  offcanvasBodyComponent={<DocumentTypeComponent />}
                />
              </Row>
              <Row>
                <ImageAddAndPreview
                  ClearImages={refreshKey}
                  Label="Document"
                  onFileUpload={handleImageUpload}
                  ColSize={8}
                  required
                />
              </Row>
              <Row>
                <TextArea
                  ColSize={8}
                  rows={3}
                  Label=" Remarks"
                  icon="fas fa-pencil-alt"
                  name="Remarks"
                  placeholder=" Remarks"
                  value={formData.Remarks}
                  onChange={(data) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      Remarks: data.target.value,
                    }));
                  }}
                />
              </Row>
              <OnClickSubmitButtonOfForm
                onClick={insertKYCDocument}
                HeadID={0}
              />
            </GlobalFormTagWithSubmit>
          </Card.Body>
          <Card>
            <AgGridComponent
              rowData={KYCDataList}
              columnDefs={columnDefs}
              height={400}
              paginationPageSize={25}
            />
          </Card>
        </Tab>

        <Tab eventKey="fetchKYC" title="Fetch KYC">
          <Card.Body>
            <Row>
              <Col md={6} sm={12} lg={6}>
                <Form.Group controlId="PolicyNo">
                  <Form.Label>Previous PolicyNo:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-file-alt"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="PreviousPolicyNo"
                      placeholder="Enter Policy No"
                      value={PolicyNoForFindKYC}
                      onChange={(e) => setPolicyNoForFindKYC(e.target.value)}
                      onKeyPress={handlePreviousPolicyNoKeyPress}
                      className="RemoveBorderRadiusTextBox"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter policyNo
                    </Form.Control.Feedback>
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ padding: "1px" }}
                    >
                      <Button
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#20336b",
                          color: "white",
                          borderRadius: "0px",
                        }}
                        variant="primary"
                        onClick={() => {
                          handlePolicyByPreviousPolicyNo(PolicyNoForFindKYC);
                        }}
                      >
                        <i className="fas fa-search"></i>
                      </Button>
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card>
            <AgGridComponent
              rowData={OtherPolicyKYCDataList}
              columnDefs={columnDefsForOtherPolicyKYCDataList}
              height={400}
              paginationPageSize={25}
            />
            <OnClickSubmitButtonOfForm
                onClick={SaveKYCofOtherPolicy}
                HeadID={0}
              />
          </Card>
        </Tab>
      </Tabs>
    </>
  );
}
